<template>
  <div class="leads">
    <div class="container fluid">
      <h1 class="p-3">Leads</h1>

      <div class="d-flex justify-content-end mb-2">
        <fd-button
          v-if="canCreateLead"
          class="medium main"
          icon="fas fa-plus"
          @click="toCreateLead"
        >
          New Lead
        </fd-button>
      </div>

      <div class="card">
        <TabsIso
          :value="currentTab"
          :tabs="tabs"
          @tabChange="tabChange"
        ></TabsIso>
      </div>
      <div class="mt-2">
        <router-view></router-view>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  components: {
    TabsIso: () => import("@/components/GlobalComponents/FdTabs/TabsIso")
  },
  mixins: [],
  props: {},
  data: function () {
    return {
      canCreateLead: false,
      canDropLead: false
    };
  },
  computed: {
    tabs() {
      let tabs = [
        {
          name: "PublicLeads",
          label: "Public",
          iconClass: "fas fa-list"
        },
        {
          name: "FollowingLeads",
          label: "Following Up",
          iconClass: "fas fa-person-walking-arrow-right"
        }
      ];

      if (this.canDropLead) {
        tabs = [
          ...tabs,
          {
            name: "AgencyLeads",
            label: "Agency Leads",
            iconClass: "fas fa-note-sticky"
          }
        ];
      }

      return tabs;
    },
    currentTab() {
      if (
        this.$route.name === "PublicLeads" ||
        this.$route.name === "FollowingLeads" ||
        this.$route.name === "AgencyLeads"
      ) {
        return this.$route.name;
      } else {
        return "PublicLeads";
      }
    }
  },
  watch: {},
  created: function () {},
  beforeDestroy: function () {},
  mounted: function () {
    this.getPermission();
  },
  methods: {
    tabChange(name) {
      this.$router.push({ name: name });
    },
    toCreateLead() {
      this.$router.push({ name: "CreateLead" });
    },
    async getPermission() {
      this.canDropLead = await this.$store.dispatch(
        "manageAccount/checkPermission",
        "lead.drop"
      );
      this.canCreateLead = await this.$store.dispatch(
        "manageAccount/checkPermission",
        "lead.create"
      );
    }
  }
};
</script>

<style lang="scss"></style>
